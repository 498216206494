import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { useSSR } from 'react-i18next'
import App from './js/App'

import './js/i18n'

const Client = () => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  return (
    <BrowserRouter> 
      <App />
    </BrowserRouter>
  )
}

const renderer = method => (
  method(<Client />, document.getElementById('app'))
)

if (process.env.ENV === 'production') {
  renderer(hydrate)
} else {
  renderer(render)

  if (module.hot) {
    module.hot.accept();
  }
}
