const industries = {
  'title': 'Secteurs d\'activité',
  'industries': {
    'industry': {
      'title': 'Industrie',
      'description': [
        'Automatisez la surveillance de vos process',
        'Analysez les situations dangereuses de vos usines et entrepôts',
        'Détectez les problèmes d\'usinage de vos pièces',
        'Valorisez vos fonds documentaires industriels',
        'Améliorez vos process pour augmenter votre productivité',
        'Enrichir vos référentiels métiers pour gagner en efficacité',
      ],
      'learn-more': {
        'title': 'Améliorer / Automatiser vos process vous permettra d\'augmenter votre productivité, d\'éviter les incidents et donc de renforcer la qualité de votre travail.',
        'description': [
          'En automatisant l\'exploitation vidéo et la reconnaissance de documents pour créer un lien métier entre tous les éléments de vos chaînes de traitement.',
        ]
      }
    },
    'health': {
      'title': 'Santé',
      'description': [
        'Démultipliez vos sources de données médicales',
        'Accélérez vos diagnostics médicaux',
        'Augmentez la célérité de vos recherches',
        'Alliez les intelligences humaines et artificielles pour impacter positivement la recherche',
      ],
      'learn-more': {
        'title': 'Améliorer la vitesse de recherche de références, de sources d\'information, d\'accès aux différentes bases de données pour aider les professionnels de santé dans leurs diagnostics et leurs recherches fondamentales, leur apporter une « deuxième opinion », leur permettre de comparer des données, de gagner du temps, etc.',
        'description': [
          'En permettant l\'accès à des datasets géants desquels pourront émaner des modèles récurrents et sur lesquels les professionnels de santé pourront s\'appuyer quotidiennement.',
        ]
      }
    },
    'retail': {
      'title': 'Retail',
      'description': [
        'Améliorez l\'analyse du trafic et du comportement de vos clients pour adapter votre offre',
        'Renforcez la surveillance et la sécurité de vos magasins et espaces connexes',
      ],
      'learn-more': {
        'title': 'L\'analyse du parcours des consommateurs vous permettra de développer des offres plus adaptées et d\'augmenter vos ventes.',
        'description': [
          'En suivant le temps passé devant chaque rayon ou chaque article par exemple. Détecter, compter, authentifier et indexer grâce à l\'alliance de briques de deep learning et d\'algorithmes de vision artificielle.',
          'En assurant grâce à l\'IA, le traitement de données de sources multiples (images, vidéos, caméras, etc.).',
          'L\'automatisation de la surveillance de vos espaces de vente et de vos espaces de stationnement vous permettra d\'assurer tant la sécurité de vos clients que celle de vos produits.',
        ]
      }
    },
    'technology': {
      'title': 'Technologie',
      'description': [
        'Upgradez vos process',
        'Libérez vos équipes des tâches à faible valeur ajoutée',
        'Automatisez vos métiers grâce à l\'IA',
      ],
      'learn-more': {
        'title': 'Utiliser des datasets performants, fiables et précis grâce à l\'analyse intelligente du Big Data : le pouvoir du machine learning et la précision de l\'IA.',
        'description': [
          'Automatiser les tâches répétitives grâce à des réseaux neuronaux auto-apprenants.',
        ]
      }
    },
    'public-sector': {
      'title': 'Secteur public',
      'description': [
        'Augmentez les capacités de vos forces de sécurité',
        'Faites de votre ville, une ville sûre',
        'Améliorez les interactions avec les usagers'
      ],
      'learn-more': {
        'title': 'Assurer la sécurité de vos concitoyens tout en améliorant leur qualité de vie. Apporter des réponses cohérentes et pertinentes aux questions des usagers pour créer de la valeur et leur offrir une expérience unique.',
        'description': [
          'Grâce aux analyses en temps réel des images de vos caméras de vidéo-protection urbaine pour rechercher les véhicules, piétons ou autres objets par type, couleur, etc.',
          'En mettant en place des canaux de communication intelligents (chatbots intelligents) basés sur des solutions combinées de NLP (traitement du langage naturel) et de Text Mining (analyse sémantique).',
        ],
      },
    },
  },
  'how': 'Comment ?',
  'learn-more': 'En savoir plus',
  'close': 'Fermer',
}

export default industries
