const industries = {
  'title': 'Industries',
  'industries': {
    'industry': {
      'title': 'Industry',
      'description': [
        'Automate the surveillance of your process',
        'Analyze dangerous situations in your factories and warehouses',
        'Detect machining problems on your parts',
        'Value your industrial documentary resources',
        'Improve your process to increase your productivity',
        'Develop your skills referential to be more efficient',
      ],
      'learn-more': {
        'title': 'Automate / Make your process better will allow you to increase your productivity, avoid incidents, and improve the quality of your work.',
        'description': [
          'By automating video exploitation and document recognition in order to bind all the elements of your processing chains.',
        ]
      }
    },
    'health': {
      'title': 'Healthcare',
      'description': [
        'Multiply your medical data sources',
        'Speed up your medical diagnoses',
        'Increase the speed of your research',
        'Combine human and artificial intelligence to positively impact research',
      ],
      'learn-more': {
        'title': 'Improve the search speed for references, sources of information, access to different databases to help health professionals in their diagnoses and basic research, provide them a "second opinion", allow them to compare data, to save time, etc.',
        'description': [
          'By allowing health professionals to consult large datasets and the resulting recurring models on a daily basis.',
        ]
      }
    },
    'retail': {
      'title': 'Retail',
      'description': [
        'Improve the analysis of the traffic and customers’ behavior to adapt your offer',
        'Strengthen the surveillance and security of your stores and other areas',
      ],
      'learn-more': {
        'title': 'Analyzing the consumer journey will allow you to develop more suitable offers and increase your sales.',
        'description': [
          'For example, by tracking the time spent in each department of your store, in front of each item. Detect, count, authenticate and index thanks to the combination of deep learning bricks and computer vision algorithms.',
          'By ensuring – thanks to AI – the data processing from different sources (images, videos, cameras, etc.).',
          'Automating the surveillance of your retail and parking spaces will allow you to ensure the safety of your customers and products.',
        ]
      }
    },
    'technology': {
      'title': 'Technology',
      'description': [
        'Upgrade your process',
        'Free your teams up from low value-added tasks',
        'Automate your trades with AI',
      ],
      'learn-more': {
        'title': 'Use powerful, reliable and accurate datasets thanks to the intelligent analysis of big data: the power of machine learning and the precision of AI.',
        'description': [
          'Automate repetitive tasks thanks to self-learning neural networks.',
        ]
      }
    },
    'public-sector': {
      'title': 'Law enforcement and smart cities',
      'description': [
        'Increase the capabilities of your law enforcement officers',
        'Make your city safe',
        'Improve interactions with users',
      ],
      'learn-more': {
        'title': 'Ensure the safety of your citizens while improving their quality of life. Provide consistent and relevant answers to users\' questions to create value and offer them a unique experience.',
        'description': [
          'Thanks to real-time analyzes of your urban video surveillance cameras to look for vehicles, pedestrians or other objects, by type, color, etc.',
          'By setting up intelligent communication channels (intelligent chatbot) based on combined NLP (Natural Language Processing) and Text Mining (semantic analysis) solutions.',
        ]
      }
    },
  },
  'how': 'How?',
  'learn-more': 'Learn more',
  'close': 'Close',
}

export default industries
