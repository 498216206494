import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Title } from '../components/Typography'
import { Button } from '../components'

const SolutionsContainer = styled.div`
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;

  @media (max-width: 1080px) {
    padding: 0 3rem;
  }
`

const SectionContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: Blinker;
    font-weight: 700;
    font-size: 1.5rem;
  }

  @media (max-width: 1080px) {
    flex-direction: column-reverse;

    &:nth-of-type(3) {
      flex-direction: column;
    }
    margin-bottom: 6rem;
  }
`

const Container = styled.div`
  margin-bottom: 8rem;

  @media (max-width: 640px) {
    height: auto;
  }

  @media (max-width: 1080px) {
    margin: 0;
    padding: 0;

    ${SectionContainer}:nth-child(2) {
      flex-direction: column;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-top: 4rem;

  @media (max-width: 1080px) {
    margin-bottom: 2rem;
  }

`

const Video = styled.video`
  width: 60%;
  min-width: 60%;
  border: 0;

  @media (max-width: 1080px) {
    width: 100%;
  }
`

const DescriptionContainer = styled.div`
  text-align: ${({ align }) => align};

  @media (max-width: 1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
  }
`

const Line = styled.div`
  width: 30%;
  height: 1px;
  background-color: #cccccc;
  margin: 5rem auto 5rem auto;

  @media (max-width: 1080px) {
    width: 60%;
  }
`

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: #fff;

  &:hover {
    background-color: #3da6d8;
  }

  @media (max-width: 1080px) {
    margin-top: 1rem;
  }
`

const NeurobotImg = styled.img`
  width: 50%;
  min-width: 50%;
  height: auto;
  margin-left: 8rem;

  @media (max-width: 1080px) {
    margin-left: 0;
    width: 100%;
  }
`

const Solutions = ({ }) => {
  const { t } = useTranslation()

  const Image = () => (
    <NeurobotImg
      src='/static/images/solutions/neurobot.jpg'
      alt='Robot pointing at you'
      loading='lazy'
    />
  )

  const Videos = ({ src, poster }) =>(
    <Video
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline={true}
      poster={poster}
    >
      <source src={src.mp4} type='video/mp4' />
      <source src={src.webm} type='video/webm' />
    </Video>
  )

  const MemoImage = React.memo(Image);
  const MemoVideos = React.memo(Videos);

  return (
    <SolutionsContainer id='solutions'>
      <StyledTitle>{t('solutions.title')}</StyledTitle>
      <Container>
        <SectionContainer>
          <DescriptionContainer align='justify'>
            <h2>{t('solutions.solutions.neurocr.title')}</h2>
            <h3>{t('solutions.solutions.neurocr.subtitle')}</h3>
            <p>{t('solutions.solutions.neurocr.list-description')}</p>
            <ul>
              {t('solutions.solutions.neurocr.list-content', { returnObjects: true }).map((li, i) => (
                <li key={`solutions-neurocr-list-${i}`}>{li}</li>
              ))}
            </ul>
            {t('solutions.solutions.neurocr.description', { returnObjects: true }).map((desc, i) => (
              <p key={`solutions-neurocr-description-${desc}`}>{desc}</p>
            ))}
            <br />
            <StyledButton onClick={() => window.open('https://neurocr.ai', '_blank').focus()}>{t('solutions.button')}</StyledButton>
          </DescriptionContainer>
          <MemoVideos
            src={{
              mp4: '/static/videos/schema-neurocr.mp4',
              webm: '/static/videos/schema-neurocr.webm'
            }}
            poster='/static/images/poster_schema-neurocr.jpg'
          />
        </SectionContainer>

        <Line />

        <SectionContainer>
          <MemoVideos
            src={{
              mp4: '/static/videos/schema-neurocop.mp4',
              webm: '/static/videos/schema-neurocop.webm'
            }}
            poster='/static/images/poster_schema-neurocop.jpg'
          />
          <DescriptionContainer align='justify'>
            <h2>{t('solutions.solutions.neurocop.title')}</h2>
            <h3>{t('solutions.solutions.neurocop.subtitle')}</h3>
            {t('solutions.solutions.neurocop.description', { returnObjects: true }).map((desc, i) => (
              <p key={`solutions-neurocop-description-${desc}`}>{desc}</p>
            ))}
            <br />
            <StyledButton onClick={() => window.open('https://neurocop.ai', '_blank').focus()}>{t('solutions.button')}</StyledButton>
          </DescriptionContainer>
        </SectionContainer>

        <Line />

        <SectionContainer>
          <DescriptionContainer align='justify'>
            <h2>{t('solutions.solutions.neurobot.title')}</h2>
            <h3>{t('solutions.solutions.neurobot.subtitle')}</h3>
            {t('solutions.solutions.neurobot.description', { returnObjects: true }).map((desc, i) => (
              <p key={`solutions-neurobot-description-${desc}`}>{desc}</p>
            ))}
            <br />
            {/* <StyledButton>{t('solutions.button')}</StyledButton> */}
          </DescriptionContainer>

          <MemoImage />
        </SectionContainer>
      </Container>
    </SolutionsContainer>
  )
}

export default Solutions
