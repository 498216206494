import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme as DesignSystemTheme } from './components/design-system/Theme'

import 'normalize.css'

const theme = {
  ...DesignSystemTheme,
  primaryColor: '#CE6C47',
  secondaryColor: '#44b9f0',
  input: {
    backgroundColor: 'transparent',
    borderColor: '#fff'
  },
  label: {
    color: '#777777',
    backgroundColor: '#fff'
  }
}

const GlobalStyle = createGlobalStyle`
  :root, body {
    height: 100%;
  }
  html {
    scroll-behavior: smooth;
    font-size: 0.975rem;
  }
  body {
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #222;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
