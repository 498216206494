import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// French
import fr_header from './fr/header'
import fr_intro from './fr/intro'
import fr_industries from './fr/industries'
import fr_solutions from './fr/solutions'
import fr_contact from './fr/contact'
import fr_footer from './fr/footer'
import fr_terms from './fr/terms'

// English
import en_header from './en/header'
import en_intro from './en/intro'
import en_industries from './en/industries'
import en_solutions from './en/solutions'
import en_contact from './en/contact'
import en_footer from './en/footer'
import en_terms from './en/terms'

const langs = {
  fr: {
    translation: {
      header: { ...fr_header },
      intro: { ...fr_intro },
      industries: { ...fr_industries },
      solutions: { ...fr_solutions },
      contact: { ...fr_contact },
      footer: { ...fr_footer },
      terms: { ...fr_terms },
    },
  },
  en: {
    translation: {
      header: { ...en_header },
      intro: { ...en_intro },
      industries: { ...en_industries },
      solutions: { ...en_solutions },
      contact: { ...en_contact },
      footer: { ...en_footer },
      terms: { ...en_terms },
    },
  },
}

const options = {
  load: 'languageOnly',
  resources: langs,
  debug: false
}

if (typeof window !== 'undefined') {
  i18n.use(initReactI18next)
}

if (!i18n.isInitialized) {
  i18n.init(options)
}

export default i18n
