const intro = {
  'expertise-ia1': 'EXPERTISE',
  'expertise-ia2': 'IA',
  'description': [
    'L\'Intelligence Artificielle (IA) évolue rapidement et représente un formidable levier de croissance pour les entreprises. Elle transforme notre vision des choses et notre façon de travailler au quotidien.',
    'Experte dans ce domaine, Neuronalys développe des solutions logicielles basées sur l\'Intelligence Artificielle pour répondre aux besoins non couverts par les technologies conventionnelles et vous accompagne dans la transition et l\'optimisation de vos process métiers.',
    'Vous souhaitez passer du stade de l\'expérimentation à la mise en oeuvre et booster votre productivité ?',
  ],
  'button': 'En savoir plus',
}

export default intro
