const intro = {
  'title': 'Contactez-nous',
  'subtitle': 'Nous sommes à l’écoute de vos questions et de vos demandes d’informations',
  'button': 'En savoir plus',
  'inputs': {
    'lastname': 'Nom',
    'firstname': 'Prénom',
    'company': 'Société',
    'email': 'Email',
    'phone': 'Téléphone',
    'message': 'Votre message',
  },
  'form-success': 'Merci, votre demande a été envoyée. À très vite.',
  'terms': 'En soumettant ce formulaire, j\'accepte que les informations saisies soient traitées pour permettre de me recontacter dans le cadre d\'une relation commerciale.',
  'captcha': 'Captcha requis',
  'legal': 'Accord légal requis',
  'button': 'Envoyer',
  'errors': {
    'lastname': 'Nom requis',
    'firstname': 'Prénom requis',
    'company': 'Société requis',
    'phone': 'Téléphone requis',
    'email': 'Email requis',
    'email-format': 'Email format incorrect',
    'message': 'Message requis',
    'legal': 'Accord légal requis',
    'captcha': 'Captcha requis',
  },
  'error': 'Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ',
  'invalid-format': 'Format non valide',
  'required': 'Requis'
}

export default intro
