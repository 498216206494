import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import HCaptcha from '@hcaptcha/react-hcaptcha'

import * as services from '../services'

import { CheckCircleIcon, Icon, CheckIcon, Button, TextInput } from '../components'
import { Title } from '../components/Typography'

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.secondaryColor};
  padding-top: 3.375rem;;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    height: auto;
  }

  @media (max-width: 800px) {
    padding-bottom: 2rem;
  }
`

const StyledTitle = styled(Title)`
  margin-top: 2rem;
  color: #fff;
`

const Subtitle = styled.p`
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 1080px) {
    padding: 0 1rem;
  }
`

const Grid = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
  max-width: 1280px;
  padding: 0 1rem;

  @media (max-width: 1080px) {
    display: flex !important;
    flex-direction: column;
    width: calc(100% - 4rem);
    margin: 0 2rem;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  background-color: #fff;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.15);
  grid-row-start: 1;
  grid-row-end: 3;
  margin-right: 1rem;

  a:last-of-type {
    margin-bottom: 2rem;
  }

  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const Logo = styled.img`
  margin-top: 2rem;
  width: 60%;
  align-self: center;
`

const Mesh = styled.img`
  width: 200px;
  margin-top: 2rem;
  align-self: flex-end;
`

const P = styled.p`
  font-family: Saira;
  font-weight: 400;
  font-size: 1.15rem;
  text-align: left;
  margin: 0 2rem;
  margin-block-end: 0;
  margin-block-start: 1em;
  color: #000;
`

const StyledButton = styled(Button)`
  background-color: #000;
  align-self: center;
  color: #fff;

  &:hover {
    background-color: #303030;
  }

  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`

const ContactIcon = styled.img`
  height: 5rem;
  width: 5rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -2.5rem;
`

const AllInputContainer = styled.div`
  display: flex;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`

const InputInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  z-index: 0;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }

  & > * {
    background-color: #fff;
  }
`

const Textarea = styled.div`
  border: none;
  background-color: #fff;
  font-family: Saira;
  font-size: 1.15rem;
  font-weight: 400;
  padding: .8rem;
  border-radius: 5px;
  width: calc(100% - 1.6rem);
  margin-left: 1rem;

  @media (max-width: 1080px) {
    height: 12rem;
    margin-left: 0;
    margin-top: 1rem;
  }
`

const SendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

const CheckboxContainer = styled.div`
  display: flex;
  color: #fff;
  margin-top: 1.5rem;

  & > *:first-child {
    margin-right: 1rem;
  }
`

const Form = styled.form`
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`

const Success = styled.div`
  text-align: center;
  margin: 2rem;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 5rem;
  height: 5rem;
  color: #92DE42;
  margin: 1rem auto;
`

const FormGroupCaptcha = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  div {
    margin: 0 !important;
  }
`

const Error = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.errorRed};

  ${Icon} {
    margin-right: 1rem;
  }
`

const Checkbox = styled.div`
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;

  ${Icon} {
    color: #000;
  }
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Contact = ({ }) => {
  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(null)
  const [onRequest, setOnRequest] = useState(false)
  const [sent, setSent] = useState(false)

  const [captchaToken, setCaptchaToken] = useState('')

  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [legal, setLegal] = useState(false)

  const { t } = useTranslation()

  const handleVerificationSuccess = (
    captchaToken => setCaptchaToken(captchaToken)
  )

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(lastname.length <= 0) ? <>{t('contact.errors.lastname')}<br /></> : null}
          {(firstname.length <= 0) ? <>{t('contact.errors.firstname')}<br /></> : null}
          {(email.length <= 0) ? <>{t('contact.errors.email')}<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>{t('contact.errors.email-format')}<br /></> : null}
          {(company.length <= 0) ? <>{t('contact.errors.company')}<br /></> : null}
          {(phone.length <= 0) ? <>{t('contact.errors.phone')}<br /></> : null}
          {(message.length <= 0) ? <>{t('contact.errors.message')}<br /></> : null}
          {!legal ? <>{t('contact.errors.legal')}<br /></> : null}
          {captchaToken.length <= 0 ? <>{t('contact.errors.captcha')}<br /></> : null}
        </Error>
      )
    } else if (onRequestError === 500) {
      return (
        <Error>
          {t('contact.error')}
          <ErrorLink target='_blank' rel='noopener noreferrer' href='mailto:contact@neuronalys.ai'>
            contact@neuronalys.ai
          </ErrorLink>
        </Error>
      )
    }

    return null
  }

  const onSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    setOnRequest(true)
    setOnError(false)
    setOnRequestError(null)

    if (
      firstname.length <= 0
      || lastname.length <= 0
      || email.length <= 0
      || !EMAIL_REGEX.test(email)
      || phone.length <= 0
      || company.length <= 0
      || message.length <= 0
      || !legal
      || captchaToken.length <= 0
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    services
      .createContact({
        firstname,
        lastname,
        company,
        email,
        phone,
        legal,
        message,
        captchaToken
      }).then(() => {
        setSent(true)
        setOnRequest(false)
      }).catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  if (sent) {
    return (
      <Container id='contact'>
        <Success>
          <StyledCheckCircleIcon />
          <StyledTitle>{t('contact.form-success')}</StyledTitle>
        </Success>
      </Container>
    )
  }

  return (
    <Container id='contact'>
      <StyledTitle>{t('contact.title')}</StyledTitle>
      <Subtitle>{t('contact.subtitle')}</Subtitle>
      <ContactIcon
        src='/static/images/contact/picto.png'
        alt='Neuronalys round logo'
        loading='lazy'
      />
      <Grid>
        <Card>
          <Logo
            src='/static/images/contact/logo-black.svg'
            alt='Black and white Neuronalys logo'
            loading='lazy'
          />
          <Mesh
            src='/static/images/contact/mesh.png'
            alt='Black decorative mesh'
            loading='lazy'
          />
          <P>
            104 Rue Nationale<br />
            59800 Lille<br />
            FRANCE
          </P>
          <P as='a' href='tel:+33374096664'>+33.3.74.09.66.64</P>
          <P as='a' href='mailto:contact@neuronalys.ai'>
            contact@neuronalys.ai
          </P>
        </Card>
        <Form onSubmit={onSubmit}>
          <AllInputContainer>
            <InputInfoContainer>
              <TextInput
                required
                label={t(`contact.inputs.lastname`)}
                value={lastname}
                name='lastname'
                onChange={setLastname}
                validator={v => v.trim().length === 0 ? t('contact.required') : null}
              />
              <TextInput
                required
                label={t(`contact.inputs.firstname`)}
                value={firstname}
                name='firstname'
                onChange={setFirstname}
                validator={v => v.trim().length === 0 ? t('contact.required') : null}
              />
              <TextInput
                required
                label={t(`contact.inputs.company`)}
                value={company}
                name='company'
                onChange={setCompany}
                validator={v => v.trim().length === 0 ? t('contact.required') : null}
              />
              <TextInput
                required
                label={t(`contact.inputs.email`)}
                value={email}
                name='email'
                onChange={setEmail}
                validator={v => !EMAIL_REGEX.test(v) ? t('contact.errors.email-format') : null}
              />
              <TextInput
                required
                label={t(`contact.inputs.phone`)}
                value={phone}
                name='phone'
                onChange={setPhone}
                validator={v => v.trim().length === 0 ? t('contact.required') : null}
              />
            </InputInfoContainer>
            <Textarea>
              <TextInput
                required
                inputAs='textarea'
                name='message'
                label={t(`contact.inputs.message`)}
                value={message}
                validator={v => v.trim().length === 0 ? t('contact.required') : null}
                onChange={setMessage}
              />
            </Textarea>
          </AllInputContainer>

          <SendContainer>
            <CheckboxContainer>
              <Checkbox
                type='checkbox'
                id='terms'
                onClick={() => setLegal(!legal)}
              >
                {legal && <CheckIcon />}
              </Checkbox>
              <label id='terms'>{t('contact.terms')}</label>
            </CheckboxContainer>
            <FormGroupCaptcha>
              <HCaptcha
                sitekey={process.env.HCAPTCHA_SITEKEY}
                onVerify={token => handleVerificationSuccess(token)}
              />
            </FormGroupCaptcha>
            {showError()}
            <StyledButton type='submit'>{t('contact.button')}</StyledButton>
          </SendContainer>
        </Form>
      </Grid>
    </Container>
  )
}

export default Contact
