import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  background-color: #000;
  font-size: .8rem;
  color: #fff;

  div {
    flex-grow: 1;
    text-align: center;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    padding: .5rem 0;
    flex-direction: column;
    justify-content: center;
  }
`

const Footer = ({ }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container>
      <div>
        {t('footer.copyright') + ' - '}
        <a onClick={() => history.push('/legal-notices')}>
          {t('footer.legal')}
        </a>
      </div>
      <div>{t('footer.copyright-app', { app: 'Neuronalys' })}</div>
    </Container>
  )
}

export default Footer
