import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Title } from '../components/Typography'
import { Button, AngleRightIcon } from '../components'

const Container = styled.div`
  height: calc(100vh - 3.375rem);
  min-height: 877px;
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;
  padding-top: 3.375rem;;

  @media (max-width: 640px) {
    height: auto;
    min-height: auto;
    margin-top: 1rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div {
    p:first-of-type {
      margin-top: 2rem !important;
    }

    p:last-of-type {
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;

    div:first-of-type {
      position: absolute;
      z-index: 1;
    }

    div:last-of-type {
      margin: auto;
      justify-content: center;
      position: unset;
    }

    div:last-of-type > p {
      padding: 0 2rem;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    div:last-of-type {
      padding: 0;
    }

    div {
      && > :first-child {
        margin-top: 1rem;
      }
    }
  }
`

const Mesh = styled.video`
  border: 0;
  width: 60%;
  min-width: 60%;
  z-index: -2;

  @media (max-width: 1080px) {
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

const P = styled.p`
  font-family: Saira;
  font-weight: 400;
  font-size: 1.15rem;
  text-align: justify;
  align-self: baseline;
  -webkit-align-self: baseline;

  @media (max-width: 640px) {
    text-align: left;
  }
`

const Orange = styled.span`
  color: ${({ theme }) => theme.primaryColor};
`

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: #fff;

  &:hover {
    background-color: #3da6d8;
  }

  @media (min-width: 1080px) {
    align-self: baseline;
    -webkit-align-self: baseline;
  }
`

const StyledTitle = styled(Title)`
  display: block;

  @media (max-width: 1080px) {
    margin-top: 2rem !important;
  }
`

const Info = styled.div`
  z-index: 0;
`

const Intro = ({ }) => {
  const { t } = useTranslation()

  return (
    <Container id='intro'>
      <FlexContainer>
        <Mesh
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          poster='/static/images/poster_orange-mesh.jpg'
        >
          <source
            src='/static/videos/orange-mesh.mp4'
            type='video/mp4'
          />
          <source
            src='/static/videos/orange-mesh.webm'
            type='video/webm'
          />
        </Mesh>

        <Info>
          <StyledTitle>{t('intro.expertise-ia1')}<Orange>.</Orange>{t('intro.expertise-ia2')}</StyledTitle>
          {t('intro.description', { returnObjects: true }).map((content, i) => (
            <P key={`intro-desc-${i}`}>{content}</P>
          ))}
          <StyledButton
            onClick={() => {
              const tabScroll = document.getElementById('contact');

              window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': tabScroll.offsetTop - 120,
              });
            }}
            rightIcon={<AngleRightIcon />}
          >
            {t('intro.button')}
          </StyledButton>
        </Info>
      </FlexContainer>
    </Container>
  )
}

export default Intro
