const contact = {
  'title': 'Contact us',
  'subtitle': 'We are at your disposal for all requests',
  'button': 'Learn more',
  'inputs': {
    'lastname': 'Name',
    'firstname': 'First name',
    'company': 'Company',
    'email': 'Email',
    'phone': 'Phone number',
    'message': 'Your message',
  },
  'form-success': 'Thank you, your request has been sent. See you soon.',
  'terms': 'By submitting this form, I agree that the information entered may be used to contact me within the framework of a commercial relationship.',
  'captcha': 'Captcha required',
  'legal': 'Legal agreement required',
  'button': 'Submit',
  'errors': {
    'lastname': 'Name required',
    'firstname': 'First name required',
    'company': 'Company name required',
    'phone': 'Phone number required',
    'email': 'Email required',
    'email-format': 'Invalid email format',
    'message': 'Message required',
    'legal': 'Legal agreement required',
    'captcha': 'Captcha required',
  },
  'error': 'An error has occurred, please try later or contact an administrator via ',
  'invalid-format': 'Invalid format',
  'required': 'Required'
}

export default contact
