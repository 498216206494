
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`

const TabsUl = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
  padding: 0;
  font-family: Blinker;
  font-size: 1.125rem;
  text-align: center;
`

const TabsLi = styled.li`
  padding: 0 1.25rem .313rem 1.25rem;
  border-bottom: ${({ theme, selected }) => selected ? '5px solid ' + theme.primaryColor : 'none'};
  color: ${({ theme, selected }) => selected ? theme.primaryColor : theme.black};
  transition: color 0.25s ease;
  transition: border-bottom-color 0.25s ease;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryHover};
    border-bottom-color: ${({ theme }) => theme.primaryHover};
  }
`

const TabsContent = styled.div`
  flex: 1;
`

export const Tabs = ({ currentTab, onChange, panes, ...props }) => {
  const tabs = panes.map(p => (
    <TabsLi
      selected={p.key.toString() === currentTab}
      key={p.key.toString()}
      onClick={() => onChange(p.key)}
    >
      {p.title}
    </TabsLi>
  ))

  const content = panes.find(e => e.key.toString() === currentTab).content

  return (
    <TabsContainer {...props}>
      <TabsUl>
        {tabs}
      </TabsUl>
      <TabsContent>
        {content}
      </TabsContent>
    </TabsContainer>
  )
}

Tabs.propTypes = {
  onChange: PropTypes.func,
  currentTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  panes: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      title: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
}

Tabs.defaultProps = {
  onChange: () => { },
  currentTab: null,
}
