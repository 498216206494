import React from 'react'
import styled from 'styled-components'

import { H1 } from '../components'

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`

const StyledTitle = styled(H1)`
  font-size: 1.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 5px;
  align-self: end;
  margin: auto;

  @media (max-width: 640px) {
    font-size: 2.2rem;
  }
`

export const Title = ({ children, ...props }) => (
  <TitleContainer {...props}>
    <StyledTitle>{children}</StyledTitle>
  </TitleContainer>
)
