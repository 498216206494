import React, { useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Title } from '../components/Typography'

import { P } from '../components'

const Container = styled.div`
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;
  padding-top: 7rem;
  padding-bottom: 4rem;
  position: relative;

  h1 {
    margin-bottom: 2rem !important;
  }

  h2 {
    font-family: Blinker;
    font-weight: 700;
    font-size: 2rem;
  }

  @media (max-width: 1080px) {
    padding: 0 3rem;
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
`

const StyledP = styled(P)`
  margin-top: 0.875rem;
`

export default () => {
  const { t } = useTranslation()

  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Container>
      <Title>{t('terms.intro.title')}</Title>

      {t('terms.intro.description', { returnObjects: true }).map((line, lineIndex) => {
        if (line.split('www.neuronalys.ai').length === 2) {
          return (
            <Fragment key={`terms-intro-${lineIndex}`}>
              <StyledP>
                {line.split('www.neuronalys.ai')[0]}
                <a href='https://www.neuronalys.ai'>www.neuronalys.ai</a>
                {line.split('www.neuronalys.ai')[1]}
              </StyledP>
            </Fragment>
          )
        } else {
          return (
            <StyledP key={`terms-intro-${lineIndex}`}>
              {line}
            </StyledP>
          )
        }
      })}

      {t('terms.content.categories', { returnObjects: true}).map((category, categoryIndex) => (
        <div key={`terms-${categoryIndex}`}>
          <h2>{category.title}</h2>
          {category.sections.map((section, sectionIndex) => (
            <div key={`terms-${categoryIndex}-${sectionIndex}`}>
              <h3>{section.title}</h3>
              {section.content.map((content, contentIndex) => (
                <StyledP key={`terms-${categoryIndex}-${sectionIndex}-${contentIndex}`}>
                  {content}
                </StyledP>
              ))}
            </div>
          ))}
        </div>
      ))}
    </Container>
  )
}
