import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'

export const Navbar = styled.nav`
  z-index: 1000;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  background-color: ${({ theme }) => theme.navbar.backgroundColor};
  color: ${({ theme }) => theme.navbar.color};
`

Navbar.propTypes = {
  theme: PropTypes.shape({
    navbar: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string
    })
  })
}

export const NavbarBrand = styled(RouterNavLink)`
  cursor: pointer;
  display: inline-block;
  padding: .8125rem .5rem;
  line-height: 0;
  white-space: nowrap;

  @media (min-width: 992px) {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
`

export const NavbarToggler = styled.button`
  cursor: pointer;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;

  &:active, &:focus, &:hover {
    outline: none;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

export const NavbarCollapse = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;

  display: none;
  flex: 1;
  align-items: center;
  border-top: 1px solid white;
  padding: .8125rem 0;
  padding-right: 0;

  background-color: ${({ theme }) => theme.navbar.backgroundColor};

  ${({ collapsed }) => !collapsed && 'display: flex;'}

  @media (min-width: 992px) {
    position: initial;
    width: auto;
    display: flex !important;
    flex-basis: auto;
    padding: 0;
    border: 0;
    justify-content: space-between;
  }
`

NavbarCollapse.propTypes = {
  collapsed: PropTypes.bool
}

export const NavbarNav = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 992px) {
    padding-left: 1rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

export const SubNav = styled.ul`
  display: block;
  margin-left: 1rem;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.navbar.subnav.backgroundColor};
  color: ${({ theme }) => theme.navbar.subnav.color};
  list-style: none;
  border-left: 1px solid white;

  @media (min-width: 992px) {
    display: none;
    position: absolute;
    z-index: 999;
    top: 100%;
    width: 200px;
    border-left: 0;
    margin: 0;

    ${({ stick }) => stick === 'right' ? 'right: 0;' : 'left: 0;'}
  }
`

SubNav.propTypes = {
  theme: PropTypes.shape({
    navbar: PropTypes.shape({
      subnav: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string
      })
    })
  })
}

export const NavItem = styled.li`
  position: relative;

  @media (min-width: 992px) {
    padding: 0 0 0 1rem;
  }

  &:hover ${SubNav} {
    display: block;
  }
`

const NavLinkStyle = css`
  color: ${({ theme }) => theme.navbar.link.color};
  cursor: pointer;
  display: block;
  padding: .5rem;

  font-family: Blinker;
  font-weight: bold;
  font-size: 1.125rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.navbar.link.hoverColor};
  }

  ${({ disabled }) => disabled && `
    opacity: .75;
    pointer-events: none;
  `}

  &.active {
    color: ${({ theme }) => theme.navbar.link.activeColor};
  }

  @media (min-width: 992px) {
    padding-right: .5rem;
    padding-left: .5rem;
  }
`

NavLinkStyle.propTypes = {
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
    navbar: PropTypes.shape({
      link: PropTypes.shape({
        color: PropTypes.string
      })
    })
  }),
  disabled: PropTypes.bool,
  active: PropTypes.bool
}

export const NavLink = styled(RouterNavLink)`
  ${NavLinkStyle}
`

NavLink.propTypes = NavLinkStyle.propTypes

export const SubNavToggler = styled.div`
  ${({ active, theme }) => active && `color: ${theme.navbar.link.activeColor};`}
`

SubNavToggler.propTypes = {
  active: PropTypes.bool
}

export const SubNavItem = styled.li``

export const SubNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.navbar.link.color};
  cursor: pointer;
  display: block;
  padding: .5rem 0;

  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.navbar.link.hoverColor};
  }

  ${({ active, theme }) => active && `color: ${theme.navbar.link.activeColor};`}

  @media (min-width: 992px) {
    padding-right: .5rem;
    padding-left: .5rem;
  }
`

SubNavLink.propTypes = NavLink.propTypes
