const terms = {
  'intro': {
    'title': 'TERMS OF USE',
    'description': [
      'These terms of use have been written in accordance with the Articles 6-III and 19 of the French Law n°2004-575 of June 21, 2004 for the Confidence in the Digital Economy. Please read them carefully before using the Neuronalys® website.',
      'Neuronalys® website can be accessed at the following address: www.neuronalys.ai.',
      'By accessing Neuronalys® website, the user fully accepts the applicable laws and/or regulations as well as the terms of use detailed below.',
      'The word "user" refers to all Internet users who access, connect, browse, read, view and use Neuronalys® website.',
    ],
  },
  'content': {
    'categories': [
      {
        'title': 'ARTICLE 1: LEGAL INFORMATION',
        'sections': [
          {
            'title': 'a) The editor:',
            'content': [
              'The www.neuronalys.ai website is edited by Neuronalys®, a simplified joint-stock company with a capital of €52.633, registered under the number 853145845 in the Lille Metropole Trade and Companies Register.',
              'Its headquarters is located at 104 rue nationale, 59 800 Lille.',
              'Phone number: + 33 3 74 09 66 64.',
              'Email address: contact@neuronalys.ai.',
              'Its intra-community VAT number is the following: FR09853145845.',
            ],
          },
          {
            'title': 'b) The editorial director:',
            'content': [
              'The editorial director of the website www.neuronalys.ai is Fabien GAVORY. He can be contacted at the following email address: contact@neuronalys.ai.',
            ]
          },
          {
            'title': 'c) The host:',
            'content': [
              'The host of the website www.neuronalys.ai is the company Digital Ocean.',
              'Its headquarters is located in New York, NY. 101 6th Ave.',
              'Phone number: +1 347-903-7918.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 2: PURPOSE OF THE WEBSITE',
        'sections': [
          {
            'title': '',
            'content': [
              'This website is dedicated to provide free information about Neuronalys®, its product NeurOCR®, NeuroCop®, NeuroBot™ and the services associated. Therefore, the information contained in this website is not contractual and does not create any business relationship between Neuronalys® and the user. To conclude any kind of agreement, please contact the company directly at the above-mentioned contact details.',
              'Furthermore, Neuronalys® declines any obligation or liability for any errors or omissions in the content of this website.',
              'Neuronalys® also declines any responsibility for any damages – be it indirect, consequential or incidental – including, but not limited to, loss of profit or income, business interruption and loss of data resulting from – or in connection with – the use, the inability to use or the trust placed in any content of this website or any linked website, and the use of all or part of the information contained herein.',
              'Moreover, Neuronalys® reserves the right to modify and update, at any time and without prior notice, the contents or any other information published on the website www.neuronalys.ai.'
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 3: ACCESSIBILITY',
        'sections': [
          {
            'title': '',
            'content': [
              'The Neuronalys® website is, in theory, accessible to users from any location, 7 days a week, 24 hours a day, except in case of interruption for maintenance (scheduled or not) or in case of force majeure.',
              'In case of non-accessibility to Neuronalys® website, Neuronalys® commits to do its best to restore access as soon as possible.',
              'Nevertheless, the website www.neuronalys.ai cannot be held responsible for any damage – whatever its nature – resulting from its unavailability.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 4: COLLECTION AND PROTECTION OF PERSONAL DATA',
        'sections': [
          {
            'title': '',
            'content': [
              'Neuronalys® takes the security of your personal data very seriously.',
              'Thus, the Neuronalys® website ensures the user a collection and processing of personal data in compliance with the Law n°78-17 of January 6, 1978 relating to data processing, data files and individual liberties and the General Data Protection Regulation (RGPD) n°2016/679 of the European Parliament and the Council of April 27, 2016 relating to the protection of natural persons with regard to the processing of personal data and on the free movement of such data.',
              'Neuronalys® informs the users of the website www.neuronalys.ai that any personal data collected via the contact form will be processed only in a strictly professional context (potential business relationship).',
              'Moreover, according to the above-mentioned law and regulation, the user has the right to access, rectify, delete and oppose his personal data. The user can exercise this right by email to the following address: contact@neuronalys.ai or by registered mail with acknowledgement of receipt addressed to Neuronalys® at the above-mentioned address (Art. 1 - a).',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 5: COOKIES',
        'sections': [
          {
            'title': '',
            'content': [
              'A cookie is used to record information relating to the user\'s navigation on a website; so, it does not allow to identify the user. The user can deactivate this cookie through the parameters of his browser software.',
              'The website www.neuronalys.ai may ask you to accept cookies for statistical and display purposes.',
              'For your information, only "Google Analytics" cookies are used on this website for statistical purposes to better understand user behavior (trend analysis, number of visits, time spent on each page/section, user path, etc.) in order to improve our communication and services.',
              'Consequently, we process the data collected by cookies as non-personal data.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 6: INTELLECTUAL PROPERTY, APPLICABLE LAW AND JURISDICTION',
        'sections': [
          {
            'title': 'a) The website www.neuronalys.ai:',
            'content': [
              'The whole website www.neuronalys.ai – content and presentation – is a work protected by the French and international legislation on intellectual and industrial property and is the exclusive property of Neuronalys®.',
              'All content – including but not limited to texts, graphics, information, patterns, logos, designs, layouts, databases, images, sounds, videos, icons, domain names, html codes - is protected by the above-mentioned law and remains the property of Neuronalys®.',
              'Any use, borrowing, reproduction, distribution, commercialization or modification of all or part of the content of the website www.neuronalys.ai, without the prior written permission of the editor is prohibited and may result in legal action as provided by the Code of Intellectual Property and the Civil Code.',
            ]
          },
          {
            'title': 'b) Registered trademarks:',
            'content': [
              'Neuronalys®, NeurOCR® et NeuroCop® are trademarks registered with the French National Institute of Industrial Property (INPI). Their respective registration numbers are: 18108455 and 18159185.',
              'Any use, modification or borrowing of the above-mentioned registered trademarks without a written agreement from Neuronalys® is strictly forbidden and may lead to legal proceedings.',
            ]
          },
          {
            'title': 'c) Applicable law:',
            'content': [
              'The website www.neuronalys.ai and the registered trademarks Neuronalys®, NeurOCR® et NeuroCop® are governed by French law.',
              'In the absence of an out-of-court settlement, any dispute relating to the interpretation, execution or termination of the present contract shall be submitted to the legally competent courts.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 7: CONTACT',
        'sections': [
          {
            'title': '',
            'content': [
              'For any report of illicit contents or activities, the user can contact the editor at the following email address: contact@neuronalys.ai, or by registered mail with acknowledgement of receipt addressed to the editor at the above-mentioned address (Art. 1 - a).',
              'Furthermore, at the same contact details, the user may inform Neuronalys® of any concerns regarding the processing of his/her data and report a possible violation of the law relating to personal data.',
              'Neuronalys® is committed to answering its users\' requests as soon as possible. Additional information may be asked to the user in case his/her request needs further investigation.',
              'In case the answer given by Neuronalys® does not satisfy the user, we invite him/her to address any complaint to the French National Commission for Information Technology and Liberties (CNIL).',
              'Neuronalys® wishes you a pleasant visit on the website www.neuronalys.ai!',

            ]
          },
        ],
      },
    ]
  },
}

export default terms
