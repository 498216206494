const solutions = {
  'title': 'Solutions',
  'button': 'En savoir plus',
  'solutions': {
    'neurocr': {
      'title': 'NeurOCR®',
      'subtitle': 'Le pouvoir de l\'IA et du Machine Learning au service de vos ressources documentaires.',
      'list-description': 'Solution de reconnaissance de caractères (OCR : Optical Character Recognition), qu’ils soient :',
      'list-content': [
        'normés, standardisés, manuscrits, dactylographiés ou complexes',
        'inclus dans un document simple en format standard, dans un plan en format hors normes, dans un PDF, dans une image, etc.',
      ],
      'description': [
        'L\'acquisition des données est possible depuis tout type de document. Les modules de restitution (tout type de format) vous permettront l\'exploitation de ces données grâce à l\'IA.',
        'NeurOCR, solution pour optimiser la gestion de vos ressources documentaires en les rendant accessibles (indexation et restitution), utilisables (rationalisation et analyse) et pérennes (amélioration et enrichissement).',
        'NeurOCR, solution d\'Intelligence Artificielle au service de la gestion documentaire, basée sur des réseaux neuronaux profonds et des algorithmes auto-apprenants.'
      ]
    },
    'neurocop': {
      'title': 'NeuroCop®',
      'subtitle': 'Le pouvoir de l\'IA et des algorithmes de vision artificielle au service de vos ressources vidéo.',
      'description': [
        'Solution de détection et de reconnaissance automatique de véhicules, piétons et autres objets ou défauts, par exploitation de caméras de zones urbaines, industrielles ou commerciales. Les éléments détectés sont ensuite indexés et mis à disposition via une interface web de recherche.',
        'NeuroCop, solution d\'extraction d\'informations à partir d\'images pour les catégoriser, les indexer et faciliter leur traitement.',
        'NeuroCop, solution de détection pour augmenter votre efficacité opérationnelle, que ce soit pour accélérer des recherches, concourir à la sécurisation d\'un lieu, gérer la foule, analyser le trafic, etc.'
      ]
    },
    'neurobot': {
      'title': 'NeuroBot™',
      'subtitle': 'Le pouvoir de l\'IA et des techniques de traitement du langage naturel (NLP) au service de vos clients et usagers.',
      'description': [
        'NeuroBot, solution d’agent conversationnel (chatbot) intelligent, évolutif et apprenant pour créer des conversations entre l\'homme et la machine, qui enverra une image positive de votre entreprise et créera une interaction renforcée avec vos clients ou usagers.',
        'NeuroBot, solution de compréhension du langage naturel et de la sémantique (compréhension du texte et du contexte) pour apporter des réponses cohérentes et pertinentes à vos clients et usagers.',
      ]
    },
  },
  'button': 'Voir le site dédié',
}

export default solutions
