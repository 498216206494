import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Intro from '../modules/Intro'
import Industries from '../modules/Industries'
import Solutions from '../modules/Solutions'
import Contact from '../modules/Contact'
import Footer from '../modules/Footer'

export default () => {
  const { t } = useTranslation()

  const linksText = t('header.links', { returnObjects: true })
  const linksKeys = Object.keys(linksText)

  useEffect(() => {
    const location = window.location.pathname.split('/')[1]

    if (location === '') {
      window.scrollTo(0, 0)
      return
    }

    if (location === 'contact') {
      const tabScroll = document.getElementById('contact');

      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 120,
      });
      return
    }

    let tmpLinksKeys = ['intro']
    tmpLinksKeys = tmpLinksKeys.concat(linksKeys.slice(0, linksKeys.indexOf(location)))

    const scrollTo = tmpLinksKeys.reduce((acc, current) => {
      return (
        acc += document.getElementById(current).scrollHeight
      )
    }, 0)

    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': scrollTo - 54,
    });
  })

  return (
    <>
      <Intro />
      <Industries />
      <Solutions />
      <Contact />
      <Footer />
    </>
  )
}
