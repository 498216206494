import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  BurgerMenuIcon,
  SendIcon,
  AngleUpIcon,
  Icon,
} from '../components'

const RelativeContainer = styled.div`
  position: relative;
  z-index: 5;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const WidthContainer = styled(FlexContainer)`
  max-width: 1280px;
  padding: 0.296rem 0;
  margin: auto;
  height: 100%;

  @media (max-width: 950px) {
    padding: 5px 0;
  }
`

const NavigationContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #000;
`

const Logo = styled.img`
  height: 3rem;
  width: auto;
  cursor: pointer;

  @media (max-width: 1280px) {
    margin-left: 1rem;
  }

  @media (max-width: 950px) {
    height: 2.5rem;
  }
`

const Space = styled.div`
  flex-grow: 1;
`

const LinksContainer = styled(FlexContainer)`
  width: 100%;
  row-gap: .8rem;
  margin-left: 2rem;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }

  @media (max-width: 950px) {
    & > *:not(:first-child) {
      margin-left: 1rem;
    }

    margin-left: 0;
    position: absolute;
    top: 100%;
    background-color: #000;
    flex-direction: column;
    align-items: end;
    row-gap: .8rem;
    padding: 1rem .2rem;
    border-top: 1px solid #FFF;

    ${Space} {
      display: none;
    }

    ${({ collapsed }) => collapsed && 'display: none'}
  }
`

const Link = styled.div`
  font-family: Blinker;
  font-weight: bold;
  font-size: 1.125rem;
  text-decoration: none;
  color: ${({ theme, selected }) => selected ? theme.primaryColor : '#fff'};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }

  transition: all 0.15s ease-out;

  @media (max-width: 950px) {
    display: block;
    margin-left: 1rem;
  }
`

const ContactLink = styled(Link)`
  display: flex !important;
  align-items: center;
  margin-right: 2rem !important;

  ${Icon} {
    margin-right: .4rem;
  }

  @media (max-width: 950px) {
    margin-right: 2rem !important;
  }
`

const MobileMenuIcon = styled(BurgerMenuIcon)`
  display: none;
  color: ${({ theme }) => theme.primaryColor};
  min-width: 60px;
  cursor: pointer;
  padding: 0 0.75rem;

  @media (max-width: 950px) {
    display: block;
  }
`

const LanguagesContainer = styled.div`
  display: flex;

  img:last-of-type {
    margin-left: 1rem;
  }


  @media (max-width: 1280px) {
    img:last-of-type {
      margin-right: 1rem;
    }
  }
`

const Language = styled.img`
  height: 1.5rem;
  width: auto;
  align-self: center;
  cursor: pointer;
  opacity: ${({ selected }) => selected ? '1' : '0.4'};
`

const Button = styled.button`
  background-color: transparent;
  border: none;
`

const ToTopContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 5;

  ${Icon} {
    height: 2rem;
    width: 2rem;
    color: ${({ theme }) => theme.primaryColor};
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`

const ToTop = styled.div`
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 5rem 5rem;
  border-color: transparent transparent #000 transparent;
`

const Navigation = () => {
  const location = useLocation()

  const { t, i18n } = useTranslation()

  const [collapsed, setCollapsed] = useState(true)
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const [currentSection, setCurrentSection] = useState(null)
  const [buttonToTop, setButtonToTop] = useState(false)

  if (location.pathname === '/legal-notices' && currentSection) {
    setCurrentSection(null)
  }

  const el = useRef(null)

  const history = useHistory()

  const linksText = t('header.links', { returnObjects: true })
  const linksKeys = Object.keys(linksText)

  const changeLang = newLanguage => {
    i18n.changeLanguage(newLanguage, () => setCurrentLanguage(newLanguage))
  }

  const scrollToSection = (key, index) => {
    if (window.location.pathname !== '/') {
      history.push(`/${key}`)
      return
    }

    let tmpLinksKeys = ['intro']
    tmpLinksKeys = tmpLinksKeys.concat(linksKeys.slice(0, index))

    const scrollTo = tmpLinksKeys.reduce((acc, current) => {
      return (
        acc += document.getElementById(current).scrollHeight
      )
    }, 0)

    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': scrollTo - 54,
    });
  }

  const handleClick = (e) => {
    if (el.current.contains(e.target))
      setCollapsed(!collapsed)
    else
      setCollapsed(true)
  }

  const handleScroll = () => {
    if (window.location.pathname === '/legal-notices') {
      setCurrentSection(null)
      return
    }

    const yPosition = window.pageYOffset
    const navigationHeight = 54
    // let positions = null

    if (document.readyState === 'complete') {
      // positions = Object.keys(t('header.links', { returnObjects: true })).map(e => (
      //   document.getElementById(e).offsetTop - 54
      // ))

      if (yPosition >= 0 && yPosition < document.getElementById('intro').scrollHeight - navigationHeight) {
        setCurrentSection(null)
        setButtonToTop(false)
      } else {
        setButtonToTop(true)
      }

      if (
        yPosition >= document.getElementById('intro').scrollHeight - navigationHeight &&
        yPosition < (document.getElementById('intro').scrollHeight + document.getElementById('industries').scrollHeight - navigationHeight)
      ) {
        setCurrentSection('industries')
      }

      if (
        yPosition >= (document.getElementById('intro').scrollHeight + document.getElementById('industries').scrollHeight - navigationHeight) &&
        yPosition < (document.getElementById('intro').scrollHeight + document.getElementById('industries').scrollHeight - navigationHeight + document.getElementById('solutions').scrollHeight - navigationHeight)
      ) {
        setCurrentSection('solutions')
      }

      if (
        yPosition >= (document.getElementById('intro').scrollHeight + document.getElementById('industries').scrollHeight - navigationHeight + document.getElementById('solutions').scrollHeight - navigationHeight) &&
        yPosition < (document.getElementById('intro').scrollHeight + document.getElementById('industries').scrollHeight - navigationHeight + document.getElementById('solutions').scrollHeight - navigationHeight + document.getElementById('contact').scrollHeight - navigationHeight)
      ) {
        setCurrentSection('contact')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () =>
      document.removeEventListener('click', handleClick, true);
  }, [collapsed])

  return (
    <>
      <RelativeContainer>
        <NavigationContainer>
          <WidthContainer>
            <Logo
              src='/static/images/header/logo.svg'
              alt='Neuronalys logo'
              onClick={() => {
                if (window.location.pathname === '/') {
                  window.scrollTo(0, 0)
                } else {
                  history.push('/')
                }
              }}
              loading='lazy'
            />
            <LinksContainer collapsed={collapsed}>
              {
                linksKeys.map((key, i) => (
                  <Link
                    key={`header-link-${key}`}
                    selected={key === currentSection}
                    onClick={() => scrollToSection(key, i)}
                  >
                    {linksText[key]}
                  </Link>
                ))
              }
              <Space />
              <ContactLink
                selected={currentSection === 'contact'}
                onClick={() => {
                  const tabScroll = document.getElementById('contact');

                  if (window.location.pathname !== '/') {
                    history.push('/contact')
                    return
                  }

                  window.scrollTo({
                    'behavior': 'smooth',
                    'left': 0,
                    'top': tabScroll.offsetTop - 120,
                  });
                }}
              >
                <SendIcon color='#CE6C47' />
                {
                  t('header.contact')
                }
              </ContactLink>
            </LinksContainer>
            <Space />
            <Button ref={el}>
              <MobileMenuIcon />
            </Button>
            <LanguagesContainer>
              <Language
                selected={currentLanguage === 'fr'}
                onClick={() => changeLang('fr')}
                src={`/static/images/header/fr.svg`}
                loading='lazy'
              />
              <Language
                selected={currentLanguage === 'en'}
                onClick={() => changeLang('en')}
                src={`/static/images/header/en.svg`}
                loading='lazy'
              />
            </LanguagesContainer>
          </WidthContainer>
        </NavigationContainer>
      </RelativeContainer>
      {
        buttonToTop &&
        <ToTopContainer
          onClick={() => {
            window.scrollTo({
              'behavior': 'smooth',
              'left': 0,
              'top': 0,
            });
          }}
        >
          <AngleUpIcon />
          <ToTop />
        </ToTopContainer>
      }
    </>
  )
}

export default Navigation
