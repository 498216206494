import React, { memo } from 'react'
import 'normalize.css'
import './style.css'
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import 'core-js'
import 'regenerator-runtime/runtime'

import { ThemeProvider, GlobalStyle, theme } from './Theme'

import Navigation from './modules/Navigation'
import Home from './pages/Home'
import Footer from './modules/Footer'
import Terms from './pages/Terms'

const App = () => {
  const Legals = () => <>
    <Terms />
    <Footer />
  </>

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div style={{ flex: '1 0 auto' }}>
          <Navigation />
          <Switch>
            <Route exact path={["/", "/industries", "/solutions", "/contact"]} component={Home} />
            <Route exact path="/legal-notices" component={Legals} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </>
  )
}

export default memo(App)
