const solutions = {
  'title': 'Solutions',
  'button': 'Learn more',
  'solutions': {
    'neurocr': {
      'title': 'NeurOCR®',
      'subtitle': 'The power of AI and Machine Learning for your documentary resources.',
      'list-description': 'Optical Character Recognition (OCR) solution:',
      'list-content': [
        'normalized, standardized, handwritten, typed or complex',
        'included in a simple document, in a drawing, in a standard and non-standard format, in a PDF, in an image, etc.',
      ],
      'description': [
        'Data acquisition is possible from any type of document. The retrieval modules (any type of format) will allow you to use this data thanks to AI.',
        'NeurOCR, solution to optimize the management of your documentary resources by making them accessible (indexing and restitution), usable (rationalization and analysis) and lasting (improvement and enrichment).',
        'NeurOCR, Artificial Intelligence solution developed for document management and based on deep neural networks and self-learning algorithms.'
      ]
    },
    'neurocop': {
      'title': 'NeuroCop®',
      'subtitle': 'The power of AI and computer vision algorithms to make the best use of your video resources.',
      'description': [
        'Automatic detection and recognition solution for vehicles, pedestrians and other objects through the use of cameras in urban, industrial or commercial areas. Detected elements are then indexed and available on a web search interface.',
        'NeuroCop, solution for extracting information from images to categorize them, index them and facilitate their processing.',
        'NeuroCop, detection solution to increase your operational efficiency, be it to speed up searches, make a site safe, control the crowd, analyze traffic, etc.'
      ]
    },
    'neurobot': {
      'title': 'NeuroBot®',
      'subtitle': 'The power of AI and Natural Language Processing (NLP) techniques to help your customers and users.',
      'description': [
        'NeuroBot, intelligent, scalable and self-learning chatbot solution to create human-machine conversations, that will send a positive image of your company and create enhanced interaction with your customers or users.',
        'NeuroBot, natural language and semantic understanding solution (text and context understanding) to provide adequate and relevant answers to your customers and users.',
      ]
    },
  },
  'button': 'See dedicated website',
}

export default solutions
