import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Title } from '../components/Typography'
import {
  Icon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '../components'

import { useWindowDimensions } from '../utils'

const Container = styled.div`
  height: calc(100vh - 3.375rem);
  min-height: 877px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;

  @media (max-width: 1080px) {
    padding: 0 3rem;
    height: auto;
    min-height: unset;
    flex-direction: column;
    margin-top: 4rem;
  }
`

const IndustriesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  margin: 0;
  padding: 0;

  @media (max-width: 1080px) {
    height: auto;
  }
`

const TabContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
  width: 65%;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.15);

  @media (max-width: 1080px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    margin-bottom: 1rem;
    margin-top: 0;
  }
`

const ImageSliderContainer = styled.div`
  position: absolute;
  width: 85%;
  top: 8rem;
  left: 0;
  height: calc(100% - 8rem);

  @media (max-width: 1080px) {
    position: unset;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
  }
`

const RelativeContainer = styled.div`
  position: relative;
  height: 90%;
  width: 100%;
`

const RelativeContainerImages = styled(RelativeContainer)`
  overflow: hidden;
`

const ImageContainer = styled.img`
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;

  @media (max-width: 1080px) {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

const DescriptionContainer = styled.div`
  z-index: 2;
  position: absolute;
  margin: auto;
  top: -7%;
  bottom: 0;
  right: -17.65%;
  height: 70%;
  background-color: #fff;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.15);
  width: ${({ learnMore }) => learnMore ? '100%' : '60%'};
  overflow: hidden;
  transition: all .8s ease-in-out;

  @media (max-width: 1080px) {
    position: unset;
    width: 100%;
    height: auto;
    padding: 2rem 0;
  }
`

const DescriptionTextContainer = styled.div`
  height: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  width: ${({ learnMore }) => learnMore ? 'calc(50% - 4rem)' : 'calc(100% - 4rem)'};
  transition: all .8s ease-in-out;

  @media (max-width: 1080px) {
    height: auto;
    width: calc(100% - 4rem) !important;
    position: unset;
    background-color: transparent;
  }
`

const LearnMoreContainer = styled(DescriptionTextContainer)`
  height: auto;
  display: flex;
  position: absolute;
  left: ${({ learnMore }) => learnMore ? '50%' : '100%'};
  top: 0;
  width: calc(50% - 4rem);
  ${({ learnMore }) => !learnMore && 'width: 100%;'}
  overflow-y: auto;
  transition: all .8s ease-in-out;
  height: 100%;

  div {
    padding: 2rem 0;
  }

  @media (max-width: 1080px) {
    height: auto;
    position: unset;
    padding: 2rem;
    display: ${({ learnMore }) => !learnMore ? 'none' : 'auto'};

    div {
      padding: 0 2rem;
    }
  }
`

const StyledH2 = styled.h2`
  font-family: Blinker;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin: 0;
`

const FakeLink = styled.a`
  color: #44b9f0;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`

const TabGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-around;
  grid-template-columns: repeat(${({ col }) => col + 2}, auto);
  text-align: center;
  cursor: pointer;
  margin-top: 2.7rem;

  ${Icon} {
    color: #44b9f0;
  }

  @media (max-width: 1080px) {
    display: block;
    margin-top: 0;

    ${Icon} {
      display: none;
    }
  }
`

const TabName = styled.div`
  font-family: Saira;
  font-size: 1.2rem;
  width: fit-content;
  padding-bottom: ${({ selected }) => selected ? '0' : '2px' };
  ${({ selected, theme }) => selected && 'border-bottom: 2px solid ' + theme.primaryColor + ';'}
  transition: all 0.15s ease-out;
  user-select: none;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }

  @media (max-width: 1080px) {
    display: inline-block;
    margin: .5rem .7rem;
  }
`

const TitleContainer = styled.div`
  position: absolute;
  top: 1.4rem;
  left: 4.5rem;
  max-width: 18rem;

  @media (max-width: 1080px) {
    position: unset;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
  }
`

const Industries = ({ }) => {
  const [currentIndustry, setCurrentIndustry] = useState('industry')
  const [learnMore, setLearnMore] = useState(false)

  const slider = () => setCurrentIndustry(industriesKeys[industriesKeys.indexOf(currentIndustry) + 1]);
  const sliderRewind = () => setCurrentIndustry(industriesKeys[0]);

  const { t } = useTranslation()
  const { width } = useWindowDimensions();

  const industriesList = t('industries.industries', { returnObjects: true })
  const industriesKeys = Object.keys(industriesList)

  useEffect(() => {
    if (width <= 1080 || learnMore) return

    let id = null

    if (industriesKeys.indexOf(currentIndustry) === industriesKeys.length - 1)
      id = setInterval(sliderRewind, 6000)
    else
      id = setInterval(slider, 6000)

    return () => clearInterval(id)
  }, [currentIndustry, learnMore, width])

  return (
    <Container id='industries'>
      <IndustriesContainer>
        <TitleContainer>
          <Title>{t('industries.title')}</Title>
        </TitleContainer>

        <TabContainer>
          <TabGrid col={industriesKeys.length}>
            <ArrowLeftIcon
              onClick={() => {
                if (industriesKeys.indexOf(currentIndustry) === 0)
                  setCurrentIndustry(industriesKeys[industriesKeys.length - 1])
                else
                  setCurrentIndustry(industriesKeys[industriesKeys.indexOf(currentIndustry) - 1])
              }}
            />
            {industriesKeys.map(key => (
              <TabName
                selected={currentIndustry === key}
                onClick={() => setCurrentIndustry(key)}
                key={`industries-tab-${key}`}
              >
                {industriesList[key].title}
              </TabName>
            ))}
            <ArrowRightIcon
              onClick={() => {
                if (industriesKeys.indexOf(currentIndustry) === industriesKeys.length - 1)
                  setCurrentIndustry(industriesKeys[0])
                else
                  setCurrentIndustry(industriesKeys[industriesKeys.indexOf(currentIndustry) + 1])
              }}
            />
          </TabGrid>
        </TabContainer>

        <ImageSliderContainer>
          <DescriptionContainer learnMore={learnMore}>
            <DescriptionTextContainer learnMore={learnMore}>
              <StyledH2>
                {industriesList[currentIndustry].title}
              </StyledH2>
              <ul>
                {industriesList[currentIndustry].description.map((line, i) => (
                  <li key={`industries-description-${i}`}>
                    {line.split(' ').map((word, wordIndex) => (
                      wordIndex === 0
                        ? <b key={`industries-description-${i}-${wordIndex}`}>{word + ' '}</b>
                        : <span key={`industries-description-${i}-${wordIndex}`}>{word + ' '}</span>
                    ))}
                  </li>
                ))}
              </ul>
              <FakeLink onClick={() => setLearnMore(!learnMore)}>
                {
                  learnMore
                    ? t('industries.close')
                    : t('industries.learn-more')
                }
              </FakeLink>
            </DescriptionTextContainer>
            <LearnMoreContainer learnMore={learnMore}>
              <p>
                <b>
                  {industriesList[currentIndustry]['learn-more'].title}
                </b>
              </p>
              {industriesList[currentIndustry]['learn-more'].description.map((line, i) => (
                <Fragment key={`industries-learn-more-description-container${i}`}>
                 {
                  currentIndustry !== 'technology' && i === 0 &&
                  <p key={`industries-learn-more-description-${i}-how`}>
                    <b>
                      {t('industries.how')}
                    </b>
                  </p>
                 }
                  <p key={`industries-learn-more-description-${i}`}>
                    {line}
                  </p>
                </Fragment>
              ))}
            </LearnMoreContainer>
          </DescriptionContainer>

          <RelativeContainerImages>
            <ImageContainer
              src={`/static/images/industries/${currentIndustry}.png`}
            />
          </RelativeContainerImages>
        </ImageSliderContainer>
      </IndustriesContainer>
    </Container>
  )
}

export default Industries
