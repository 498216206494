const intro = {
  'expertise-ia1': 'AI',
  'expertise-ia2': 'EXPERTISE',
  'description': [
    'Artificial Intelligence (AI) is evolving quickly and represents an unbelievable growth lever for companies. It changes our vision and transforms the way we work.',
    'Neuronalys is an expert in this area. Our company develops software solutions based on AI – that meet needs not covered by conventional technologies – in order to support and help you in your business process transition and optimization.',
    'Do you want to boost your productivity and move from experimentation to implementation?',
  ],
  'button': 'Learn more',
}

export default intro
